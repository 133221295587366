.Toastify__toast {
  align-items: center;
  border-radius: 5px;
  min-height: 5rem;
}
.Toastify__toast--error {
  background: #ff7185;
  box-shadow: 0px 10px 20px #e2bbbb;
}
.Toastify__toast--success {
  background: #008000;
  box-shadow: 0px 10px 20px #a7e1cf;
}
.Toastify__toast-body {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}
.Toastify__toast-body span {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
}
.Toastify__toast-body svg {
  display: none;
}

.Toastify__close-button {
  align-self: center;
  opacity: 0.4;
}
